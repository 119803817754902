import { Link, useNavigate } from "react-router-dom";
import diadaoIcon from "../img/diadao.png";
import rightArrow from "../img/right-arrow.png";

const Header = () => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-around p-6">
      <div
        className="transition hover:translate-x-2 cursor-pointer flex items-center w-64"
        onClick={() => navigate("/")}
      >
        Jalons de la semaine
        <img className="w-6 ml-3" src={rightArrow} alt="" />
      </div>
      <img className="w-6 max-w-6" src={diadaoIcon} alt="" />
      <div
        className="transition hover:translate-x-2 cursor-pointer flex items-center w-64"
        onClick={() => navigate("/timeline")}
      >
        Timeline
        <img className="w-6 ml-3" src={rightArrow} alt="" />
      </div>
    </div>
  );
};
export default Header;
