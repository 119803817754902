import React, { useMemo, useState } from "react";
import { parseISO, format, isBefore, isToday } from "date-fns";
import { fr } from "date-fns/locale"; // Importer la locale française
import stampImg from "../../src/img/stamp.png";
import milestoneImg from "../../src/img/milestone.png";
import refreshImg from "../../src/img/refresh.png";
import { searchTasksInWorkspace } from "../services/AsanaServices";
import Loader from "./Loader";
import { useQuery, useQueryClient } from "react-query";

type ColorMap = {
  [key: string]: string;
};

const asanaColorToTailwind = (asanaColor: string) => {
  const colorMap: ColorMap = {
    "dark-pink": "bg-pink-500",
    "dark-green": "bg-green-700",
    "dark-blue": "bg-blue-700",
    "dark-red": "bg-red-700",
    "dark-teal": "bg-teal-700",
    "dark-brown": "bg-yellow-400", // Tailwind n'a pas de marron, utilisez une teinte de jaune foncé
    "dark-orange": "bg-orange-700",
    "dark-purple": "bg-purple-500",
    "dark-warm-gray": "bg-gray-700",
    "light-pink": "bg-pink-400",
    "light-green": "bg-green-300",
    "light-blue": "bg-blue-500",
    "light-red": "bg-red-400",
    "light-teal": "bg-teal-300",
    "light-brown": "bg-yellow-300", // Tailwind n'a pas de marron, utilisez une teinte de jaune clair
    "light-orange": "bg-orange-300",
    "light-purple": "bg-purple-400",
    "light-warm-gray": "bg-gray-300",
    none: "bg-transparent", // ou une autre couleur par défaut
  };

  return colorMap[asanaColor] || "bg-transparent"; // Couleur par défaut si non trouvée
};

const PortfolioMilestones: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState("all");
  const [selectedOwners, setSelectedOwners] = useState<string[]>([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [ownersList, setOwnersList] = useState<string[]>([
    "Lenny Roland",
    "Merwen BA",
    "Teddy Vincent",
  ]);

  const workspaceId = "10748056421752";

  // Function to save data to session storage
  const saveToSessionStorage = (data: any) => {
    sessionStorage.setItem("milestonesData", JSON.stringify(data));
  };

  // Function to load data from session storage
  const loadFromSessionStorage = () => {
    const storedData = sessionStorage.getItem("milestonesData");
    return storedData ? JSON.parse(storedData) : null;
  };

  const queryClient = useQueryClient();

  const {
    data: tasksData,
    isLoading,
    isError,
    isFetching,
  } = useQuery("milestones", () => searchTasksInWorkspace(workspaceId), {
    staleTime: 1000 * 60 * 5, // 5 minutes cache
    initialData: () => {
      // Load initial data from session storage
      return loadFromSessionStorage();
    },
    onSuccess: (data) => {
      // Save new data to session storage
      saveToSessionStorage(data);
    },
  });

  // Function to manually refresh data
  const refreshData = () => {
    setIsRefreshing(true);
    queryClient
      .refetchQueries("milestones", {
        active: true,
        inactive: false,
      })
      .then(() => {
        setIsRefreshing(false);
      })
      .catch(() => {
        setIsRefreshing(false); // also reset on error
      });
  };

  const handleOwnerChange = (owner: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedOwners([...selectedOwners, owner]);
    } else {
      setSelectedOwners(selectedOwners.filter((o) => o !== owner));
    }
  };

  const filteredTasks = useMemo(() => {
    if (!tasksData) return [];

    let filteredTasks = tasksData.filteredAll;
    // Filtrer en fonction de l'option sélectionnée
    if (selectedOption === "milestones") {
      filteredTasks = tasksData.filteredMilestones;
    } else if (selectedOption === "approvals") {
      filteredTasks = tasksData.filteredApprovals;
    }
    if (selectedOwners.length > 0) {
      filteredTasks = filteredTasks.filter((task: any) =>
        selectedOwners.includes(task.owner)
      );
    }

    return filteredTasks;
  }, [tasksData, selectedOption, selectedOwners]);

  if (isLoading || isFetching || isRefreshing) return <Loader />;
  return (
    <>
      {!isLoading && !isError && (
        <div className="p-12">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <select
                id="countries"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-32 p-2.5 "
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                <option value="all">Tous</option>
                <option value="approvals">Approbations</option>
                <option value="milestones">Jalons</option>
              </select>
              <div className="flex ml-5">
                {ownersList.map((owner) => (
                  <label className="mx-1" key={owner}>
                    <input
                      type="checkbox"
                      checked={selectedOwners.includes(owner)}
                      onChange={(e) =>
                        handleOwnerChange(owner, e.target.checked)
                      }
                    />
                    <span className="ml-2">{owner}</span>
                  </label>
                ))}
              </div>
            </div>
            <button
              onClick={refreshData}
              className="flex bg-white rounded-lg text-black items-center p-2 ml-4"
            >
              <img className="w-7 mr-2" src={refreshImg} alt="" />
              Actualiser les données
            </button>
          </div>
          <p className="text-white text-xl text-center mb-8">Jalons en cours</p>
          <table className="mx-auto text-left w-full">
            <thead className="text-primary sticky top-0">
              <tr className="">
                <th className="rounded-l-lg bg-white "></th>
                <th className="bg-white p-4">Projet</th>
                <th className="bg-white p-4">Jalon</th>
                <th className="bg-white p-4">Dernier statut</th>
                <th className="rounded-r-lg bg-white p-4 text-center">
                  Echéance
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredTasks &&
                filteredTasks.map((milestone: any) => {
                  // Convertir la string de date en objet Date
                  const dueDate = parseISO(milestone.dueDate);

                  let formattedDate = format(dueDate, "eeee dd MMMM", {
                    locale: fr,
                  });
                  formattedDate =
                    formattedDate.charAt(0).toUpperCase() +
                    formattedDate.slice(1);
                  // Définir la couleur en fonction de si la date est passée
                  const dateColor =
                    isToday(dueDate) || !isBefore(dueDate, new Date())
                      ? "text-green-500"
                      : "text-red-500";

                  return (
                    <tr
                      className={`w-full ${
                        milestone.name.includes("Mise en ligne")
                          ? "bg-white bg-opacity-15 rounded-lg"
                          : "style-si-false"
                      }`}
                      key={milestone.gid}
                    >
                      <td
                        className={`p-4 ${
                          milestone.name.includes("Mise en ligne")
                            ? "rounded-l-lg"
                            : "style-si-false"
                        }`}
                      >
                        {milestone.resource_subtype === "milestone" ? (
                          <img className="w-7" src={milestoneImg} alt="" />
                        ) : (
                          <img className="w-7" src={stampImg} alt="" />
                        )}
                      </td>
                      <td className={`text-white font-semibold p-4`}>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://app.asana.com/0/${milestone.gid}/${milestone.gid}`}
                          className="bg-white bg-opacity-5 rounded-lg flex items-center p-2"
                        >
                          <div className="w-1/12">
                            <div
                              className={`h-5 w-5 mr-3 rounded-full ${asanaColorToTailwind(
                                milestone.projectColor
                              )}`}
                            ></div>
                          </div>
                          <p className="w-11/12">{milestone.projectName}</p>
                        </a>
                      </td>
                      <td className="text-white p-4 text-left">
                        {milestone.name}
                      </td>
                      <td className="text-white p-4 w-1/4">
                        {milestone.status}
                      </td>
                      <td
                        className={`${dateColor} p-4 w-2/12 text-center ${
                          milestone.name.includes("Mise en ligne")
                            ? "rounded-r-lg"
                            : "style-si-false"
                        }`}
                      >
                        {formattedDate}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default PortfolioMilestones;
