import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import WeeklyMilestones from "./views/WeeklyMilestones";
import TimeLine from "./views/TimeLine";
import NotFound from "./views/NotFound";
import Header from "./components/Header";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="bg-gradient-to-br from-primary to-secondary min-h-screen font-poppins text-white">
        <Router>
        <Header />
          <Routes>
            <Route path="/" element={<WeeklyMilestones />} />
            <Route path="/timeline" element={<TimeLine />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </div>
    </QueryClientProvider>
  );
}

export default App;
