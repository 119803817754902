import axios from "axios";

// Configurez votre instance Axios ou votre URL de base ici
const asanaApi = axios.create({
  baseURL: "https://app.asana.com/api/1.0",
  headers: {
    Authorization: `Bearer 2/1205518534531272/1205967356893567:b653baa150cb2b7a5b665174fab60ad1`,
  },
});

interface Block {
  startDate: Date;
  endDate: Date;
  name: string;
  group: number;
  completed: boolean; // Nouveau champ
}

interface Milestone {
  id: string;
  title: string;
  endDate: Date;
  completed: boolean;
}

async function fetchMilestoneDetails(milestoneGid: string) {
  try {
    const response = await asanaApi.get(`/tasks/${milestoneGid}`);
    const endDate = new Date(response.data.data.due_on);

    return {
      gid: response.data.data.gid,
      name: response.data.data.name,
      endDate: endDate,
      completed: response.data.data.completed,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
}

// Fonction pour récupérer les projets d'un portefeuille
async function fetchProjectsFromPortfolio(portfolioGid: string) {
  try {
    const response = await asanaApi.get(`/portfolios/${portfolioGid}/items`);
    return response.data.data.map((item: any) => ({
      id: item.gid,
      title: item.name,
      milestones: [],
    }));
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function fetchMilestonesForProject(projectGid: string) {
  try {
    const response = await asanaApi.get(`/projects/${projectGid}/tasks`);
    const milestoneDetailsPromises = response.data.data
      .filter((task: any) => task.resource_subtype === "milestone")
      .map((milestone: any) => fetchMilestoneDetails(milestone.gid));

    const milestones = (await Promise.all(milestoneDetailsPromises))
      .filter((detail) => detail !== null)
      .map((detail) => ({
        id: detail.gid,
        title: detail.name,
        endDate: detail.endDate,
        completed: detail.completed,
      }));

    const blocks = createBlocksFromMilestones(milestones, projectGid); // Passer projectGid
    return { milestones, blocks };
  } catch (error) {
    console.error(error);
    return { milestones: [], blocks: [] };
  }
}

export async function fetchPortfolioWithProjectsAndMilestones(
  portfolioGid: string
) {
  const projects = await fetchProjectsFromPortfolio(portfolioGid);
  const projectsWithMilestonesPromises = projects.map(async (project: any) => {
    const { milestones, blocks } = await fetchMilestonesForProject(project.id);
    return { ...project, blocks };
  });

  const projectsWithBlocks = await Promise.all(projectsWithMilestonesPromises);

  let completedBlocks = projectsWithBlocks.filter((project) =>
    project.blocks.every((block: any) => block.completed)
  );
  completedBlocks = transformToTimelineFormat(completedBlocks);

  // Séparation des notCompletedBlocks en actualBlocks et futureBlocks
  let actualBlocks: any = [];
  let futureBlocks: any = [];
  projectsWithBlocks.forEach((project) => {
    if (project.blocks.some((block: any) => !block.completed)) {
      if (
        project.blocks.some((block: any) =>
          isCurrent(block.startDate, block.endDate)
        )
      ) {
        actualBlocks.push(project);
      } else if (project.blocks.some((block: any) => isFuture(block.endDate))) {
        futureBlocks.push(project);
      }
    }
  });

  actualBlocks = transformToTimelineFormat(actualBlocks);
  futureBlocks = transformToTimelineFormat(futureBlocks);

  return { completedBlocks, actualBlocks, futureBlocks };
}

function isCurrent(startDate: any, endDate: any) {
  const today = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);
  return start <= today && today <= end;
}

// Fonction pour vérifier si une date est dans le futur
function isFuture(dateString: any) {
  const today = new Date();
  const date = new Date(dateString);
  return date > today;
}

function createBlocksFromMilestones(
  milestones: Milestone[],
  projectId: string
): Block[] {
  let blocks: Block[] = [];
  let startMilestones = milestones.filter((m) => m.title.includes("Début"));
  let endMilestones = milestones.filter((m) => m.title.includes("Fin"));
  let otherMilestones = milestones.filter(
    (m) => !m.title.includes("Début") && !m.title.includes("Fin")
  );

  let usedEndMilestones = new Set();

  startMilestones.forEach((start) => {
    const end = endMilestones.find(
      (e) => e.title.replace("Fin", "") === start.title.replace("Début", "")
    );

    if (end) {
      usedEndMilestones.add(end);
    }

    let startDate = start.endDate;
    let endDate = end ? end.endDate : null;
    let blockName = start.title.replace("Début", "");

    if (endDate) {
      if (startDate < startDate) {
        [startDate, endDate] = [endDate, startDate];
      }

      // Définir si le block est complété
      let isCompleted = start.completed && (end ? end.completed : false);
      blocks.push({
        startDate,
        endDate,
        name: blockName,
        group: parseInt(projectId),
        completed: isCompleted, // Ajout du champ completed
      });
    }
  });

  otherMilestones.forEach((milestone) => {
    let endDate = new Date(milestone.endDate);
    let startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - 1); // Définir la startDate comme un jour avant la endDate

    blocks.push({
      startDate, // Utiliser directement l'objet Date
      endDate, // Utiliser directement l'objet Date
      name: milestone.title,
      group: parseInt(projectId),
      completed: milestone.completed,
    });
  });

  return blocks;
}

function transformToTimelineFormat(projects: any) {
  function getRandomColor(name: string) {
    if (name.toLowerCase().includes("design")) {
      return "#E66AA9";
    } else if (name.toLowerCase().includes("développements") || name.toLowerCase().includes("intégration")) {
      return "#4573D2";
    } else if (name.toLowerCase().includes("mise en ligne") || name.toLowerCase().includes("mel")) {
      return "#F06A6A";
    } else {
      return "#F2DA6F"
    }
  }
  return projects.map((project: any) => ({
    id: project.id, // Gid du projet
    title: project.title, // Nom du projet
    elements: project.blocks.map((block: any) => ({
      id: block.id, // id du block
      title: block.name, // Nom du bloc
      start: block.startDate, // date de début
      end: block.endDate, // date de fin
      style: {
        backgroundColor: getRandomColor(block.name),
        borderRadius: 12,
        marginLeft: 1,
        marginRight: 1,
      },
    })),
  }));
}
