import { Player } from "@lottiefiles/react-lottie-player";

const Loader = () => {
  return (
    <div className="flex justify-center items-center h-screen max-h-screen">
      <Player
        autoplay
        loop
        src={require("../img/lottie.json")}
        style={{ height: "100px", width: "100px" }}
      ></Player>
    </div>
  );
};

export default Loader;
