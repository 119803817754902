import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { useQuery, useQueryClient } from "react-query";
import refreshImg from "../../src/img/refresh.png";

import { fetchPortfolioWithProjectsAndMilestones } from "../services/AsanaServicesTimeLine";
import Timeline from "react-timelines2";
import "react-timelines2/lib/css/style.css";

const TimeLine = () => {
  const [selectedOption, setSelectedOption] = useState("all");
  const [tracks, setTracks] = useState<any>([
    {
      id: "track-1",
      title: "Piste 1",
      elements: [
        {
          id: "element-1",
          title: "Tâche 1",
          start: new Date(2023, 0, 10),
          end: new Date(2023, 1, 20),
        },
      ],
    },
  ]);
  const [scale, setScale] = useState({
    start: new Date(),
    end: new Date(),
    zoom: 2,
    zoomMax: 5,
    zoomMin: 0.1,
  });
  const [timebar, setTimebar] = useState([
    {
      id: "1",
      title: "Mois",
      cells: [
        {
          id: "1-1",
          title: "Étape 1",
          start: new Date(2023, 4, 1),
          end: new Date(2023, 6, 1),
        },
        {
          id: "1-2",
          title: "Étape 2",
          start: new Date(2023, 2, 1),
          end: new Date(2023, 3, 1),
        },
      ],
    },
  ]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const queryClient = useQueryClient();
  // Function to save data to session storage
  const saveToSessionStorage = (data: any) => {
    sessionStorage.setItem("timelineData", JSON.stringify(data));
  };

  // Function to load data from session storage
  const loadFromSessionStorage = () => {
    const storedData = sessionStorage.getItem("timelineData");
    return storedData ? JSON.parse(storedData) : null;
  };

  const fetchMilestones = () =>
    fetchPortfolioWithProjectsAndMilestones("1203647882754054");

  const { data: projects, isLoading } = useQuery(
    "portfolioData",
    fetchMilestones,
    {
      initialData: loadFromSessionStorage(),
      onSuccess: (data) => {
        saveToSessionStorage(data);
      },
      onSettled: () => {
        setIsRefreshing(false);
      },
    }
  );

  const refreshData = () => {
    setIsRefreshing(true);
    sessionStorage.removeItem("timelineData"); // Clear the session storage before refreshing
    queryClient.refetchQueries("portfolioData");
  };

  useEffect(() => {
    if (projects) {
      let currentTracks;
      switch (selectedOption) {
        case "actualBlocks":
          currentTracks = projects.actualBlocks;
          break;
        case "futureBlocks":
          currentTracks = projects.futureBlocks;
          break;
        case "completedBlocks":
          currentTracks = projects.completedBlocks;
          break;
        default:
          // Combine all tracks
          currentTracks = [
            ...projects.actualBlocks,
            ...projects.futureBlocks,
            ...projects.completedBlocks,
          ];
      }
      setTracks(currentTracks);
      const { minDate, maxDate } = updateScale(currentTracks);
      updateTimebar(minDate, maxDate);
    }
  }, [projects, selectedOption]);

  const updateScale = (tracks: any) => {
    let minDate = new Date();
    let maxDate = new Date(0);

    tracks.forEach((track: any) => {
      track.elements.forEach((element: any) => {
        if (element.start <= minDate && element.start.getFullYear() > 2000) {
          minDate = new Date(
            element.start.getFullYear(),
            element.start.getMonth(),
            1
          ); // Début du mois de minDate
          minDate.setMonth(minDate.getMonth() - 3); // Soustraire 3 mois si nécessaire
        }
        if (element.end > maxDate) {
          maxDate = new Date(
            element.end.getFullYear(),
            element.end.getMonth() + 1,
            0
          ); // Fin du mois de maxDate
          maxDate.setMonth(maxDate.getMonth() + 3); // Ajouter 3 mois si nécessaire
        }
      });
    });
    setScale({ ...scale, start: minDate, end: maxDate });
    return { minDate, maxDate };
  };

  const updateTimebar = (minDate: any, maxDate: any) => {
    const quarterCells = [];
    const monthCells = [];
    let currentQuarterDate = new Date(minDate.getFullYear(), 0, 1);
    let currentMonthDate = new Date(
      minDate.getFullYear(),
      minDate.getMonth(),
      1
    );

    // Générer les trimestres
    while (currentQuarterDate <= maxDate) {
      const quarter = Math.floor(currentQuarterDate.getMonth() / 3) + 1;
      const quarterStart = new Date(
        currentQuarterDate.getFullYear(),
        (quarter - 1) * 3,
        1
      );
      const quarterEnd = new Date(
        currentQuarterDate.getFullYear(),
        quarter * 3,
        0
      );

      quarterCells.push({
        id: `quarter-${quarterStart.getFullYear()}-Q${quarter}`,
        title: `Trim ${quarter} ${quarterStart.getFullYear()}`,
        start: quarterStart,
        end: quarterEnd,
      });

      currentQuarterDate = new Date(
        quarterEnd.getFullYear(),
        quarterEnd.getMonth() + 1,
        1
      );
    }

    // Générer les mois
    while (currentMonthDate <= maxDate) {
      const monthEnd = new Date(
        currentMonthDate.getFullYear(),
        currentMonthDate.getMonth() + 1,
        0
      );

      monthCells.push({
        id: `month-${currentMonthDate.getFullYear()}-${
          currentMonthDate.getMonth() + 1
        }`,
        title: currentMonthDate.toLocaleString("default", { month: "short" }),
        start: new Date(currentMonthDate),
        end: monthEnd,
      });

      currentMonthDate.setMonth(currentMonthDate.getMonth() + 1);
    }

    setTimebar([
      { id: "quarters", title: "Trimestres", cells: quarterCells },
      { id: "months", title: "Mois", cells: monthCells },
    ]);
  };

  const handleZoomIn = () => {
    setScale((prevScale) => ({ ...prevScale, zoom: prevScale.zoom + 0.5 }));
  };

  const handleZoomOut = () => {
    setScale((prevScale) => ({
      ...prevScale,
      zoom: Math.max(0.5, prevScale.zoom - 0.5),
    })); // Assurez-vous que le zoom ne devient pas négatif
  };

  if (isLoading || isRefreshing) return <Loader />;
  return (
    <>
      <div className="p-12">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <select
              id="countries"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-32 p-2.5 "
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              <option value="all">Tous</option>
              <option value="actualBlocks">En cours</option>
              <option value="futureBlocks">Futurs</option>
              <option value="completedBlocks">Complétés</option>
            </select>
          </div>
          <button
            onClick={refreshData}
            className="flex bg-white rounded-lg text-black items-center p-2 ml-4"
          >
            <img className="w-7 mr-2" src={refreshImg} alt="" />
            Actualiser les données
          </button>
        </div>

        <p className="text-white text-xl text-center mb-8">Workload Timeline</p>
        <Timeline
          zoomIn={handleZoomIn}
          zoomOut={handleZoomOut}
          scale={scale}
          timebar={timebar}
          tracks={tracks}
          isOpen={true}
          scrollToNow={true}
          now={new Date()}
        />
      </div>
    </>
  );
};

export default TimeLine;
