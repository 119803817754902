import PortfolioMilestones from "../components/MilestonesList";

const WeeklyMilestones = () => {
  return (
    <div className="">
      <PortfolioMilestones />
    </div>
  );
};

export default WeeklyMilestones;
