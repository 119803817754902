import axios from "axios";
import { startOfWeek, endOfWeek, formatISO, addWeeks, subWeeks } from "date-fns";

const asanaApi = axios.create({
  baseURL: "https://app.asana.com/api/1.0",
  headers: {
    Authorization: `Bearer 2/1205518534531272/1205967356893567:b653baa150cb2b7a5b665174fab60ad1`,
  },
});

async function searchTasksBySubtype(
  workspaceId: string,
  subtype: string,
  start: string,
  end: string
) {
  try {
    const response = await asanaApi.get(
      `/workspaces/${workspaceId}/tasks/search`,
      {
        params: {
          resource_subtype: subtype,
          completed: "false",
          "due_at.before": end,
          "due_at.after": start,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    console.error(`Error searching tasks for subtype ${subtype}:`, error);
    return [];
  }
}

export const searchTasksInWorkspace = async (workspaceId: string) => {
  const start = formatISO(startOfWeek(subWeeks(new Date(), 1)));
  const end = formatISO(endOfWeek(addWeeks(new Date(), 1))); // Fin de la semaine suivante

  const milestones = await searchTasksBySubtype(
    workspaceId,
    "milestone",
    start,
    end
  );
  const approvals = await searchTasksBySubtype(
    workspaceId,
    "approval",
    start,
    end
  );

  const allTasks = [...milestones, ...approvals];

  const detailedMilestones = await Promise.all(
    milestones.map(async (task: any) => {
      try {
        // Récupérer les détails complets de la tâche, y compris la date d'échéance
        const taskDetailsResponse = await asanaApi.get(`/tasks/${task.gid}`);
        const taskDetails = taskDetailsResponse.data.data;

        // Ajouter la date d'échéance à l'objet de la tâche
        task.dueDate = taskDetails.due_on; // Assurez-vous que cette propriété est renvoyée

        // Récupérer la liste des projets pour la tâche
        const projectsResponse = await asanaApi.get(
          `/tasks/${task.gid}/projects`
        );
        const projects = projectsResponse.data.data;

        if (projects.length > 0) {
          // Si la tâche appartient à des projets, prenez le premier pour simplifier
          const projectId = projects[0].gid;

          // Récupérer les détails du projet spécifique
          const projectDetailsResponse = await asanaApi.get(
            `/projects/${projectId}`
          );
          const projectDetails = projectDetailsResponse.data.data;
          // Ajouter les détails du projet à l'objet de la tâche
          task.projectName = projectDetails?.name;
          task.projectColor = projectDetails?.color; // Assurez-vous que la propriété existe
          task.status = projectDetails?.current_status?.title;
          task.owner = projectDetails?.owner.name;
        }

        return task;
      } catch (error) {
        console.error(`Error getting details for task ${task.gid}:`, error);
        return task; // Retourner la tâche sans modifications supplémentaires en cas d'erreur
      }
    })
  );

  const filteredMilestones = detailedMilestones.sort((a, b) => {
    // Convertir les dates en chaînes pour la comparaison
    const dateA = a.dueDate || ""; // Utiliser une chaîne vide si pas de date
    const dateB = b.dueDate || "";

    return dateA.localeCompare(dateB);
  });
  const detailedApprovals = await Promise.all(
    approvals.map(async (task: any) => {
      try {
        // Récupérer les détails complets de la tâche, y compris la date d'échéance
        const taskDetailsResponse = await asanaApi.get(`/tasks/${task.gid}`);
        const taskDetails = taskDetailsResponse.data.data;

        // Ajouter la date d'échéance à l'objet de la tâche
        task.dueDate = taskDetails.due_on; // Assurez-vous que cette propriété est renvoyée

        // Récupérer la liste des projets pour la tâche
        const projectsResponse = await asanaApi.get(
          `/tasks/${task.gid}/projects`
        );
        const projects = projectsResponse.data.data;

        if (projects.length > 0) {
          // Si la tâche appartient à des projets, prenez le premier pour simplifier
          const projectId = projects[0].gid;

          // Récupérer les détails du projet spécifique
          const projectDetailsResponse = await asanaApi.get(
            `/projects/${projectId}`
          );
          const projectDetails = projectDetailsResponse.data.data;
          // Ajouter les détails du projet à l'objet de la tâche
          task.projectName = projectDetails?.name;
          task.projectColor = projectDetails?.color; // Assurez-vous que la propriété existe
          task.status = projectDetails?.current_status?.title;
          task.owner = projectDetails?.owner.name;
        }

        return task;
      } catch (error) {
        console.error(`Error getting details for task ${task.gid}:`, error);
        return task; // Retourner la tâche sans modifications supplémentaires en cas d'erreur
      }
    })
  );

  const filteredApprovals = detailedApprovals.sort((a, b) => {
    // Convertir les dates en chaînes pour la comparaison
    const dateA = a.dueDate || ""; // Utiliser une chaîne vide si pas de date
    const dateB = b.dueDate || "";

    return dateA.localeCompare(dateB);
  });

  const detailedAll = await Promise.all(
    allTasks.map(async (task: any) => {
      try {
        // Récupérer les détails complets de la tâche, y compris la date d'échéance
        const taskDetailsResponse = await asanaApi.get(`/tasks/${task.gid}`);
        const taskDetails = taskDetailsResponse.data.data;

        // Ajouter la date d'échéance à l'objet de la tâche
        task.dueDate = taskDetails.due_on; // Assurez-vous que cette propriété est renvoyée

        // Récupérer la liste des projets pour la tâche
        const projectsResponse = await asanaApi.get(
          `/tasks/${task.gid}/projects`
        );
        const projects = projectsResponse.data.data;

        if (projects.length > 0) {
          // Si la tâche appartient à des projets, prenez le premier pour simplifier
          const projectId = projects[0].gid;

          // Récupérer les détails du projet spécifique
          const projectDetailsResponse = await asanaApi.get(
            `/projects/${projectId}`
          );
          const projectDetails = projectDetailsResponse.data.data;
          // Ajouter les détails du projet à l'objet de la tâche
          task.projectName = projectDetails?.name;
          task.projectColor = projectDetails?.color; // Assurez-vous que la propriété existe
          task.status = projectDetails?.current_status?.title;
          task.owner = projectDetails?.owner.name;
        }

        return task;
      } catch (error) {
        console.error(`Error getting details for task ${task.gid}:`, error);
        return task; // Retourner la tâche sans modifications supplémentaires en cas d'erreur
      }
    })
  );

  const filteredAll = detailedAll.sort((a, b) => {
    // Convertir les dates en chaînes pour la comparaison
    const dateA = a.dueDate || ""; // Utiliser une chaîne vide si pas de date
    const dateB = b.dueDate || "";

    return dateA.localeCompare(dateB);
  });

  return { filteredMilestones, filteredApprovals, filteredAll };
};
