import { Player } from "@lottiefiles/react-lottie-player";

const NotFound = () => {
  return (
    <div className="h-screen flex justify-center items-center">
      <Player
        autoplay
        loop
        src={require("../img/404.json")}
        style={{ height: "500px", width: "500px" }}
      />
    </div>
  );
};

export default NotFound;
